.contact{ 
    background-color: white;
    display: flex;
    

    .left{ 
        flex:1;
        overflow: hidden;

        img{ 
            height: 100;
        }
    }
    .right{ 
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        h2{ 
            font-size: 30px;
        }
        
        form{ 
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            input{ 
                width: 300px;
                height: 30px;
                font-size: 14px;
            }

            textarea{ 
                width: 300px;
                height: 200px;
                font-size: 14px;
            }

            button{ 
                width: 150px;
                height: 30px;
                color: white;
                background-color: crimson;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;
            }

            span{ 
                color: green;
            }
        }
    }

} 

@media (max-width: 883px) {
    .contact .right { 
        flex: 2
    }
} 

@media (max-width: 695px) {
    .contact .left { 
        display: none
    }
}

@media (max-width: 353px) {
    .contact .right{ 
        form{ 
            padding-left: 10px; 
            padding-right: 10px;

            input{ 
                width: 250px;
                height: 27px;
                font-size: 14px;

            }

            textarea{ 
                width: 250px;
                height: 200px;
                font-size: 14px;

            }

        }
    }

}

