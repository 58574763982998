.works {
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow {
        height: 50px;
        position: absolute;

        &.left {
            left: 100px;
            transform: rotateY(180deg);
            cursor: pointer;
        }

        &.right {
            right: 100px;
            cursor: pointer;
        }
    }

    .slider {
        height: 350px; 
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        .container {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center; 
 
            .item {
                height: 100%; 
                width: 700px;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center; 
                margin-right: 20vh;
                margin-left: 20vh;

                .left {
                    flex: 4;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer {
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .imgContainer {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: rgb(249, 162, 130);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 25px;
                            }

                        }

                        h2 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 13px;
                        }

                        span {
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }

                .right {
                    height: 100%;
                    flex: 8;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 400px;
                        transform: rotate(-7deg); 
                        overflow: hidden; 
                    }
                }
            }
        }
    }
}

@media (max-width: 1066px) {
    .works .arrow {
        height: 30px;

        &.left {
            left: 30px;
        }

        &.right {
            right: 30px;
        }
    }

    .slider .container .item {
        width: auto;
        height: auto;
        margin-right: 10vh;
        margin-left: 10vh;
    }
}

@media(max-width: 768px) {
    .works .arrow {
        height: 20px;

        &.left {
            left: 10px;
        }

        &.right {
            right: 10px;
        }
    }

    .container {
        width: 150vw;
        height: 250px;
    }

    .item .right img {
        width: 250px;
    }

} 

@media(max-width: 740px) {
    .works {
        display: flex; 
        align-items: center;
        justify-content: center;
        position: relative;

        .arrow{ 
            &.left {
                left: 5px;
            }
    
            &.right {
                right: 5px;
            } 
        }
    
        .slider {
            height: 350px;
            display: flex;
            position: absolute;
            left: 0;
            transition: all 1s ease-out;
    
            .container {
                width: 100vw;
                display: flex;
                align-items: center; 
                align-self: center;
                justify-content: center; 

                @media(max-width: 500px) { 
                    height: 200px;
                }
    
                .item { 
                    height: 100%;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center; 
                    @media(max-width: 600px) { 
                        margin-right: 12vh;
                        margin-left: 12vh;
                    }  
                    @media(max-width: 474px) { 
                        margin-right: 8vh;
                        margin-left: 8vh;
                    } 
                    @media(max-width: 400px) { 
                        margin-right: 4vh;
                        margin-left: 4vh;
                    }   
                   
                    .left {
                        flex: 2;
                        height: 20px; 
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        .leftContainer { 
                            width: 100%;
                            height: 90%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
    
                            .imgContainer {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                background-color: rgb(249, 162, 130);
                                display: flex;
                                align-items: center;
                                justify-content: center; 
                                margin-right: 10px;

                                @media(max-width: 435) { 
                                    width: 30px; 
                                    height: 30px; 
                                }

                                img {
                                    width: 35px; 
                                    @media(max-width: 435) { 
                                        width: 30px;
                                }
                                }
    
                            }
    
                            h2 { 
                                margin-top: 5px;
                                font-size: 20px;
                                overflow: hidden;

                                @media(max-width: 600px) { 
                                    font-size: 15px
                                }
                            }
    
                            p {
                                font-size: 13px; 
                                display: none
                            }
    
                            span {
                                font-size: 12px;
                                font-weight: 600;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
    
                    .right {
                        height: 100%;
                        flex: 8;
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
    
                        img {
                            transform: rotate(-7deg);
                            overflow: hidden; 

                            @media(max-width: 476px){ 
                                width: 300px;
                            }
                        }
                    }
                }
            }
        }
    }
}