.testimonials {
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    flex-direction: column;
  
    h1 {
      font-size: 50px;
      margin-top: 20px; 

    @media(max-width: 650px){ 
        font-size: 40px;
    } 
    @media(max-width: 395px){ 
        font-size: 32px;
    } 
    }
  
    .container {
      width: 100%;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .card {
        width: 250px;
        height: 70%;
        border-radius: 10px;
        box-shadow: 0px 0px 15px -8px black;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 20px;
        transition: all 1s ease;

        @media(max-width: 650px){ 
            height: 55%;
        }
        @media(max-width: 650px){ 
            height: 35%;
        } 
  
        &.featured {
          width: 300px;
          height: 75%;
          margin: 0 30px; 
          @media(max-width: 650px){ 
            height: 55%;
        } 

        @media(max-width: 650px){ 
            height: 35%;
        }
        }
  
        &:hover {
          transform: scale(1.1);
        }
  
        .top {
          display: flex;
          align-items: center;
          justify-content: center;
  
          img {
            &.left,
            &.right {
              height: 25px; 
              @media(max-width: 600px) { 
                display: none;
              }
            }

            &.user {
              height: 60px;
              width: 60px;
              border-radius: 60%;
              object-fit: cover;
              margin: 0 30px; 

              @media(max-width:550px){ 
                margin: 0 1px;
              }

            }
          }
        }
  
        .center {
          padding: 10px;
          background-color: rgb(237, 232, 233);
          border-radius: 10px; 

          @media (max-width: 650px) {
            font-size: 10px; // Adjust font size for h4 in this media query
          }
            
          
        }
  
        .bottom {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
  
          h3 {
            margin-bottom: 5px; 
            @media (max-width: 650px) {
                font-size: 14px; // Adjust font size for h4 in this media query
              }
          }
  
          h4 {
            color: gray;
  
            @media (max-width: 650px) {
              font-size: 12px; // Adjust font size for h4 in this media query
            }
  
            // @media (max-width: 600px) {
            //   font-size: 6px; // Additional media query if needed
            // }
          }
        }
      }
    }
  
    @media (max-width: 748px) {
      .testimonials .container .card {
        height: 45%;
  
        &.featured {
          height: 48%;
        }
      }
    }
  }
  