.portfolio {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 50px;
        overflow: hidden;
    }

    ul {
        margin: 50px;
        padding: 0;
        list-style: none;
        display: flex;

        li {
            font-size: 14px;
            margin-right: 10px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;
            overflow: hidden; 
            @media(max-width: 464px){ 
                font-size: 10px;
            }

            &.active {
                background-color: black;
                color: white;
            }
        }
    }

    .container {
        width: 70%;
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background-color: white;

        .item {
            width: 220px;
            height: 150px;
            border-radius: 20px;
            border: 1px solid lightgrey;
            margin: 10px 20px;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5s ease;
            overflow: hidden;
            display: flex;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;

                h3 {
                    font-size: 20px;
                    color: white;
                    z-index: 0;
                }
            }

            &:hover {
                background-color: black;

                img {
                    opacity: 0.3;
                    z-index: 0;
                }
            }


        }
    }
}

@media(max-width: 748px) {
    .portfolio {

        h1 {
            font-size: 40px;
        }

        ul {
            margin: 8px;

            li {
                margin-right: 8px;
            }
        }

        .container {
            width: 99%;

            .item {
                width: 200px;
                height: 120px;
            }
        }
    }

}
