.intro {
    background-color: white;
    display: flex;

    .left {
        flex: 0.5;
        overflow: hidden;

        .imgContainer {
            width: 500px;
            height: 500px;
            background-color: rgba(0, 0, 0, 0.473);
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            float: right;

            img {
                width: 100%;
            }
        }
    }

    .right {
        flex: 0.5;
        position: relative;

        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-size: 60px;
                margin: 10px 0;

            }

            h2 {
                font-size: 35px;
            }

            h3 {
                font-size: 30px;
                color: crimson;

                span {
                    font-size: inherit;
                    color: crimson;
                }

                .ityped-cursor {
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    100% {
                        opacity: 100%;
                    }
                }
            }

        }

        neo a {
            position: absolute;
            bottom: 10px;
            left: 40%;

            img {
                width: 30px;
                height: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100% {
                    opacity: 0;
                }
            }

        }
    }
}

/* Media query for screens with a maximum width of 768 pixels */
@media (max-width: 992px) {

    /* Change font size and padding for smaller screens */
    .intro {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .left .imgContainer {
            width: 350px;
            height: 350px;
        }
    }
}

@media (max-width: 442px) {

    /* Change font size and padding for smaller screens */
    .intro {

        .left .imgContainer {
            width: 300px;
            height: 300px;
            justify-content: space-between;
            background-color: black;
        }

        .right .wrapper {
            width: 100%;
            height: 60%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-size: 40px;
                margin: 10px 0;

            }

            h2 {
                font-size: 23px;
            }

            h3 {
                font-size: 20px;
                color: crimson;

                span {
                    font-size: inherit;
                    color: crimson;
                }
            }
        }
    }

} 


@media (max-width: 442px) {

    /* Change font size and padding for smaller screens */
    .intro {

        .left .imgContainer {
            width: 250px;
            height: 250px;
            justify-content: space-between;
            background-color: black;
        }
    }
}